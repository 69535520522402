import React, { useState, useEffect } from "react"
import { HeaderApp } from "./Header"
import { FooterApp } from "./Footer"
import "./index.css"



const LayoutGlobal = ({ children }) => {
    // const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

    // // const imageUrl = windowWidth >= 650 ? '/images/1.jpg' : '/images/2.jpg';


    // const handleWindowResize = () => {
    //     setWindowWidth(window.innerWidth);
    // };

    // useEffect(() => {
    //     window.addEventListener('resize', handleWindowResize);

    //     return () => {
    //         window.removeEventListener('resize', handleWindowResize);
    //     }
    // });

    const imageUrl = '/images/3.jpg';
    return (
        <div className="App" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="App-Overlay">
                <HeaderApp />
                {children}
                <FooterApp />
            </div>
        </div>
    )
}
export default LayoutGlobal;
