import React from "react"
import { Layout } from 'antd';
const { Footer } = Layout;
const dt = new Date();



export const FooterApp = React.memo(() => {
    return (
        <Footer className="footer-container">
        </Footer>
    )
})
